import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// environment

import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SendDocumentService {

  constructor(
    private http: HttpClient) { }

  sendDocument(par_documento): Observable<any[]> {

    const formData = new FormData();

    formData.append('id_profesional', par_documento['id_profesional']);
    formData.append('juzgado_destino', par_documento['juzgado_destino']);
    formData.append('juzgado_descripcion', par_documento['juzgado_descripcion']);
    formData.append('juzgado_cod_nomenclador', par_documento['juzgado_cod_nomenclador']);
    formData.append('id_tipo_presentacion', par_documento['id_tipo_presentacion']);
    formData.append('numero_matricula', par_documento['numero_matricula']);
    formData.append('nombre_apellido', par_documento['nombre_apellido']);
    formData.append('numero_cuit', par_documento['numero_cuit']);
    formData.append('numero_expediente', par_documento['numero_expediente']);
    formData.append('primary', par_documento['primary']);
    formData.append('urgente', par_documento['urgente']);
    formData.append('no_publicable', par_documento['no_publicable']);

    for (let file of par_documento['file']) {
      formData.append('file', file);
    }
    // return this.http.post<any[]>(environment.backend.default + '/pdf', formData);
    return this.http.post<any[]>(environment.backend.default + '/v2/pdf', formData);
  }

  captcha(par_response): Observable<any> {

    return this.http.post<any>(environment.backend.default + '/captcha/', { 'response' : par_response });
  }
}

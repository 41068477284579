import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppAuthGuard } from "./service/guard.service"
import { BandejaEscritosComponent } from './bandeja-escritos/bandeja-escritos.component';
import { DocumentSendComponent } from './document-send/document-send.component';
import { DocumentDownloadComponent } from './document-download/document-download.component';

const routes: Routes = [
  // { path: '', component: DocumentSendComponent, canActivate: [AppAuthGuard]},
  { path: 'bandejaEscritos', component: BandejaEscritosComponent, canActivate: [AppAuthGuard]},
  { path: 'presentacionEscritos', component: DocumentSendComponent, canActivate: [AppAuthGuard]},
  { path: 'dd', component: DocumentDownloadComponent, canActivate: [AppAuthGuard]},
  { path: '', redirectTo: 'presentacionEscritos', pathMatch: 'full', canActivate: [AppAuthGuard] },
  { path: '**', redirectTo: 'presentacionEscritos' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// environment

import { environment } from './../../environments/environment';

// models

import { Juzgado } from '../models/juzgado'

@Injectable({
  providedIn: 'root'
})
export class JuzgadoService {

  constructor(
    private http: HttpClient) { }

  getJuzgados(): Observable<Juzgado[]> {

    return this.http.get<Juzgado[]>(environment.backend.default + '/juzgado/');
  }
}

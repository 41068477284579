import * as jsPDF from 'jspdf';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class UtilsService {

   generarConstanciaPDF(file) {
        let f = file["documento"]
        let adjuntos = file["documentos_asociados"]
        console.log(file);
        let fila: number;
        let doc = new jsPDF();
        let fila_en_blanco = 10;
        let fila_proxima = 8;
        
        // encabezado
        fila = 20;
        doc.setFontStyle('bold');
        doc.text('PODER JUDICIAL DE MENDOZA', 58, fila);
        fila += 10;
        doc.text('Suprema Corte de Justicia', 64, fila);
        fila += 5;
        doc.setFontStyle('normal');
        doc.line(10, fila, 200, fila);
        fila += 10;
        doc.text('== Presentación validada ==', 68, fila);
        fila += 5;
        doc.line(10, fila, 200, fila);
      
        doc.setFontSize(13);
      
        // juzgado de destino
        fila += fila_en_blanco;
        doc.setFontStyle('normal');
        doc.text('Juzgado de Destino:', 10, fila);
        doc.setFontStyle('bold');
        doc.text(f.descripcion_juzgado, 60, fila);
      
        // tipo de presentacion
        fila += fila_en_blanco;
        doc.setFontStyle('normal');
        doc.text('Tipo de Presentación:', 10, fila);
        doc.setFontStyle('bold');
        doc.text(f.descripcion_tipo_presentacion, 60, fila);
      
        // profesional
        let profesional = '';
        if (f.numero_matricula || f.nombre_apellido) {
          // si el usuario indico el numero de matricula y/o nombre y apellido
          if (f.numero_matricula) {
            profesional += f.numero_matricula;
          }
      
          if (f.nombre_apellido) {
            profesional += (f.numero_matricula ? ' - ' : '') + f.nombre_apellido;
          }
      
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Profesional:', 10, fila);
          doc.setFontStyle('bold');
          doc.text(profesional, 60, fila);
        }
      
        // numero de expediente
        if (f.numero_expediente) {
          // si el usuario indico el numero de expediente
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Expediente:', 10, fila);
          doc.setFontStyle('bold');
          doc.text(f.numero_expediente, 60, fila);
        }
        
        // caracter urgente 
        if (f._is_urgente) {
          // si el usuario indico caracter urgente
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Carácter Urgente:', 10, fila);
          doc.setFontStyle('bold');
          doc.text("SI", 60, fila);
        }
        
        // caracter no_publicable
        if (f._is_no_publicable) {
          // si el usuario indico caracter no_publicable
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Carácter No Publicable:', 10, fila);
          doc.setFontStyle('bold');
          doc.text("SI", 60, fila);
        }
      
        fila += fila_en_blanco;
        doc.setFontSize(12);
      
        // documento principal
        // for (let file of this.uploadedFiles){
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Documento enviado:', 10, fila);
          doc.setFontStyle('bold');
          doc.text(f.filename, 70, fila);
      
          // fecha y hora de envio
          let doc_date = formatDate(f["uploadDate"], 'dd-MM-yyyy HH:mm', 'en-US', '+000')
          
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Fecha y hora de envío:', 10, fila);
      
          doc.setFontStyle('bold');
          doc.text(doc_date, 70, fila);
      
          // tamaño del documento
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Tamaño del documento:', 10, fila);
      
          doc.setFontStyle('bold');
          doc.text(f.size.toString() + ' KB', 70, fila);
      
        //   // cantidad de paginas
          if (f.num_pages) {
      
            fila += fila_en_blanco;
            doc.setFontStyle('normal');
            doc.text('Cantidad de páginas:', 10, fila);
        
            doc.setFontStyle('bold');
            doc.text(f.num_pages.toString(), 70, fila);
          }
      
        //   // identificador del documento
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Identificador del documento:', 10, fila);
        
          doc.setFontStyle('bold');
          doc.text(f.short_id, 70, fila);
      
          fila += fila_en_blanco;
        // }
      
      
          // DOCUMENTOS ADJUNTOS
        for (let f of adjuntos){
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Documento enviado:', 10, fila);
          doc.setFontStyle('bold');
          doc.text(f.filename, 70, fila);
      
          // fecha y hora de envio
          let doc_date = formatDate(f["uploadDate"], 'dd-MM-yyyy HH:mm', 'en-US', '+000')
          
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Fecha y hora de envío:', 10, fila);
      
          doc.setFontStyle('bold');
          doc.text(doc_date, 70, fila);
      
          // tamaño del documento
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Tamaño del documento:', 10, fila);
      
          doc.setFontStyle('bold');
          doc.text(f.size.toString() + ' KB', 70, fila);
      
        //   // cantidad de paginas
          if (f.num_pages) {
      
            fila += fila_en_blanco;
            doc.setFontStyle('normal');
            doc.text('Cantidad de páginas:', 10, fila);
        
            doc.setFontStyle('bold');
            doc.text(f.num_pages.toString(), 70, fila);
          }
      
        //   // identificador del documento
          fila += fila_en_blanco;
          doc.setFontStyle('normal');
          doc.text('Identificador del documento:', 10, fila);
        
          doc.setFontStyle('bold');
          doc.text(f.short_id, 70, fila);
      
          fila += fila_en_blanco;
        }
      
      
          
          fila += 5;
          doc.line(10, fila, 200, fila);
        
        let filename_constancia = 'Constancia Meed - ' + f["short_id"] + '.pdf';
      
        console.log(this)
      
        doc.save(filename_constancia);
        return true;
      }

}


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { KcloakService } from './kcloak.service'
import { Doc } from '../models/docs';

@Injectable({
  providedIn: 'root'
})

export class DocumentsService {
  
  baseUrl: string = environment.backend.default
  apiPath: string = "consulta"
  apiCamioEstado: string = "documento"
  currentProfile = this.kc.getProfile()
  defaultFilter: string = "?id_profesional="+this.currentProfile.username+"&estado=todos"

  constructor(
    private http:HttpClient,
    private kc:KcloakService) { }
  
  
  getDocBySID(sid){
    let filter = "?short_id="+sid;
    let url = this.baseUrl+this.apiPath+filter;
    return this.http.get<Doc[]>(url)
  }

  getUserDocs(filter=this.defaultFilter) : Observable<Doc[]> {
    this.currentProfile = this.kc.getProfile()
    filter = "?id_profesional="+this.currentProfile.username+"&estado_descarga=todos&principal_adjunto=todos"
    let url = this.baseUrl+this.apiPath+filter;
    return this.http.get<Doc[]>(url)
  }

  documentDiscard(doc: Doc ): Observable<any> {
    let params = {
      "id" : doc.id,
      "id_estado" : "",
      "id_usuario" : this.currentProfile.username,
      "descartado" : true,
      "observaciones" : "Descartado por el usuario."
    }

    return this.http.put<any>(this.baseUrl + '/v2/documento-estado/', params);
  }

  downloadDocument(short_id: string) {

    return this.http.get(this.baseUrl + '/pdf-exist/' + short_id);
  }


}


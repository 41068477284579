import { Component, OnInit, Input } from '@angular/core';
import { DocumentsService } from '../service/documents.service';
import { Doc } from '../models/docs';
import { formatDate } from '@angular/common';
import { MessageService, SortEvent } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { UtilsService } from '../service/utils.service';


@Component({
  selector: 'app-bandeja-escritos',
  templateUrl: './bandeja-escritos.component.html',
  styleUrls: ['./bandeja-escritos.component.css'],
  providers: [MessageService, ConfirmationService]
})


export class BandejaEscritosComponent implements OnInit {
  loading: boolean;
  first: number = 0;
  rows = 10;
  totalRecords = 0;
  _completeFilterFields = ['uploadDate', 'descripcion_tipo_presentacion', 'descripcion_juzgado', 'numero_expediente', 'estado_actual', 'short_id']
  _selectedColumns: any[];
  _sortField = ""

  docs: Doc[] = [];
  cols: any[];
  totals = 0;

  fileInfo = {
    'show-delay': 50,
    'hide-delay': 500
  }

  displayModal = false
  modal_comment_user = ""
  modal_comment_date = ""
  modal_comment = ""


  constructor(private docsService: DocumentsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private utils: UtilsService) { }

  buildCols() {
    this.cols = [
      { field: 'fecha_presentacion', header: 'Fecha Presentación' },
      { field: 'descripcion_tipo_presentacion', header: 'Tipo de Presentación' },
      { field: 'mod_descripcion_juzgado', header: 'Oficina' },
      { field: 'numero_expediente', header: 'Expediente' }
    ];
  }

  buildData(data) {
    data["id"] = data["id"]
    data["fecha_presentacion"] = formatDate(data["uploadDate"], 'dd-MM-yyyy HH:mm', 'en-US', '+000')
    data["estado_observaciones"] = data["estado_ultimo"]["observaciones"]
    data["estado_fecha"] = formatDate(data["estado_ultimo"]["fecha_estado"], 'dd-MM-yyyy HH:mm', 'en-US', '+000')
    data["estado_usuario"] = data["estado_ultimo"]["id_usuario"]

    if (data["estado_ultimo"]["descripcion"] == "PENDIENTE" && data["lastdownloadDate"] != null)
      data["estado_actual"] = "ARCHIVO DESCARGADO"
    else
      data["estado_actual"] = data["estado_ultimo"]["descripcion"]


    if (data['estado_actual'] != 'PENDIENTE')
      data["discardDisabled"] = true;
    if (data['estado_observaciones'] == '' || data['estado_actual'] == 'DESCARTADO')
      data["commentDisabled"] = true;

    if (data['id_error']){
      data["discardDisabled"] = true;
      data["estado_actual"] = "ERROR"
      data["estado_fecha"] = data["fecha_presentacion"]
      data["estado_observaciones"] = "Error en archivo. Código de error: "+data['id_error']
      data["commentDisabled"] = false;
      console.log(data['id_error'])  
    }

    let cj = data["nomenclador"].substring(0, 2);
    if (cj === '01') {
      data["mod_descripcion_juzgado"] = "1° CJ - "+ data["descripcion_juzgado"];
    } else if (cj === '02') {
      data["mod_descripcion_juzgado"] = "2° CJ - "+ data["descripcion_juzgado"];
    } else if (cj === '03') {
      data["mod_descripcion_juzgado"] = "3° CJ - "+ data["descripcion_juzgado"];
    } else if (cj === '04') {
      data["mod_descripcion_juzgado"] = "4° CJ - "+ data["descripcion_juzgado"];
    }

    data["is_urgente"] = data["urgente"] ? "SI" : "NO"
    data["is_no_publicable"] = data["no_publicable"] ? "SI" : "NO"

    return data
  }

  getData() {
    this.docsService.getUserDocs().subscribe(
      result => {
        let r = result["data"]
        for (let i = 0; i < r.length; i++) {
          let doc = r[i] as Doc;
          // console.log(doc)       
          doc = this.buildData(doc)
          this.docs.push(doc);
          this.totals++;
        }
        this.totalRecords = this.totals;
        this._sortField = "fecha_presentacion"
        this.loading = false;
      },
      error => {
        console.log("OHHH THIS IS A F%&& ERRORR!!!!!")
        console.log(error)
      }
    )
  }

  ngOnInit() {
    this.loading = true;
    this.buildCols();
    this._selectedColumns = this.cols;
    this.getData();
  }

  viewComment(doc) {
    if (doc.commentDisabled) return false;
    // console.log(doc.short_id,doc.estado_observaciones)
    this.modal_comment_user = doc.estado_usuario
    this.modal_comment_date = doc.estado_fecha
    this.modal_comment = doc.estado_observaciones
    this.displayModal = true;
  }

  cleanDialog() {
    console.log("Clean Dialog FC.")
    this.modal_comment_user = ""
    this.modal_comment_date = ""
    this.modal_comment = ""
  }

  discardDoc(doc) {
    if (doc.discardDisabled) return false;
    // console.log(doc.id)
    // this.loading = true;

   

    this.docsService.documentDiscard(doc).subscribe(data => {
      if (data['code']) {
        // doc.discardDisabled = true;
        // doc.estado_actual = "DESCARTADO"
        for (let i = 0; i < this.docs.length; i++) {
          let iterDoc = this.docs[i] as Doc;
          if(iterDoc["id_presentacion"] == doc["id_presentacion"]){
            // console.log(iterDoc)
            iterDoc["discardDisabled"] = true;
            iterDoc["estado_actual"] = "DESCARTADO";
          }
        }
        this.messageService.add({ severity: 'success', summary: '', detail: 'Se ha descartado el documento junto con sus archivos adjuntos.' });

      } else if (data['message_code'] === 'err-estado-descartado-not-allowed') {
        // console.log(data.data)
        let estado_udapted = data.data
        // doc["estado_ultimo"] = data.data
        // doc = this.buildData(doc)
        for (let i = 0; i < this.docs.length; i++) {
          let iterDoc = this.docs[i] as Doc;
          if(iterDoc["id_presentacion"] == doc["id_presentacion"]){
            console.log(iterDoc)
            iterDoc["discardDisabled"] = false;
            iterDoc["estado_actual"] = data.data["descripcion"];
          }
        }
        this.messageService.add({ severity: 'error', summary: '', detail: 'No se puede descartar el escrito.\nMotivo: Escrito no Pendiente.' });
      } else {
        this.messageService.add({ severity: 'error', summary: '', detail: 'No se puede descartar el escrito.\nIntente nuevamente refrescar su bandeja.' });
      }
      this.loading = false;
    })


  }


  confirmDiscard(doc) {
    if (doc.discardDisabled) return false;
    this.confirmationService.confirm({
      message: 'Está seguro que desea descartar el escrito seleccionado y sus adjuntos?',
      header: 'Confirmar Acción',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.discardDoc(doc)
      }
    });
  }


  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  isLastPage(): boolean {
    return this.first === (this.docs.length - this.rows);
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }

  customSort(event: SortEvent) {
    // console.log('SortEvent=', event);
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        if ((event.field === 'fecha_presentacion')) {

          value1 = formatDate(data1["uploadDate"], 'yyyy-MM-dd', 'en-US', '+000')
          value2 = formatDate(data2["uploadDate"], 'yyyy-MM-dd', 'en-US', '+000')
          // console.log('x=', value1);
          // console.log('y=', value2);
        }
        result = value1.localeCompare(value2);
      } else { result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0; }
      return (event.order * result);
    });
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  descargarConstancia(short_id){
    this.docsService.getDocBySID(short_id).subscribe(
      result => {
        let docById = result["data"]
        this.utils.generarConstanciaPDF(docById)
        
      },
      error => {
        console.log("OHHH THIS IS A F%&& ERRORR!!!!!")
        console.log(error)
      }
    )

  }


}





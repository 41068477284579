import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {FileUploadModule} from 'primeng/fileupload';

// environment

import { environment } from './../../environments/environment';

// primeng 

// import { MessagesService } from '../service/messages.service';
import { MessageService } from 'primeng/api';

// pdf

import * as jsPDF from 'jspdf';
import * as JsBarcode from 'jsbarcode';

// models

import { Juzgado } from '../models/juzgado';
import { TipoPresentacion } from '../models/tipo-presentacion';

// services

import { SendDocumentService } from '../service/send-document.service'
import { JuzgadoService } from '../service/juzgado.service'
import { TipoPresentacionService } from '../service/tipo-presentacion.service'
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { KcloakService  } from '../service/kcloak.service';
// import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { ConfirmationService } from 'primeng/api';

import { DocumentsService } from '../service/documents.service';
import { UtilsService } from '../service/utils.service';

@Component({
  selector: 'app-document-send',
  templateUrl: './document-send.component.html',
  styleUrls: ['./document-send.component.css'],
  providers: [MessageService, ConfirmationService]
})

export class DocumentSendComponent implements OnInit {
  primarySelected = 0;
  filesToUpload: File[] = [];
  primary: string = "";
  docsInfo: File[] = [];
  maxAdjFiles = 0;

  mensaje_success = ""

  title: string = 'mecc';
  showProgressSpinner = false;
  messageProgressSpinner = '';

  showSenDocument = true;
  radio_cj = '';
  juzgados_cj1: Juzgado[] = [];
  juzgados_cj2: Juzgado[] = [];
  juzgados_cj3: Juzgado[] = [];
  juzgados_cj4: Juzgado[] = [];
  dpdn_juzgados_destino: Juzgado[] = [];
  dpdn_juzgado_destino_selected: Juzgado = undefined;
  dpdn_juzgados_destino_disabled: boolean = true;
  dpdn_tipos_presentacion: TipoPresentacion[] = [];
  dpdn_tipo_presentacion_selected: TipoPresentacion = undefined;

  username = '';
  numero_matricula = '';
  nombre_apellido = '';
  numero_cuit = '';
  in_numero_expediente = '';
  file: any = null;
  urgente: boolean = false;
  no_publicable: boolean = false;


  showCancelButton: boolean = false;
  showPanelValidated: boolean = false;
  showPanelNotValidated: boolean = false;
  showPanelMultipleFiles: boolean = false;
  showDialog: boolean = false;

  filename: string;
  num_pages: number;
  short_id: string;
  size: number;
  mensaje_error: string = '';
  disabled: boolean = false;
  label_boton_enviar_id: string = 'Enviar identificador';

  storageMatriculas = [];

  rexp_numero_expediente: RegExp = /^[a-zA-Z0-9 /-]*$/; 

  uploadedFiles = []

  scriptSrc: string = '';
  
  @ViewChild('ppalfile', {static: false}) ppalFile: FileUploadModule;
  @ViewChild('adjfile', {static: false}) adjFile: FileUploadModule;

  constructor(
    private docsService: DocumentsService,
    private utils: UtilsService,
    private sendDocumentService: SendDocumentService,
    private messageService: MessageService,
    private juzgadoService: JuzgadoService,
    private tipoPresentacionService: TipoPresentacionService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private confirmationService: ConfirmationService,
    private kCloakService: KcloakService) {}
    // private messagesService: MessagesService,

  ngOnInit() {

    // obtengo script y site key de google reCaptcha
    this.scriptSrc = environment.reCaptcha.reCAPTCHA_SCRIPT + environment.reCaptcha.reCAPTCHA_SCORE;

    this.radio_cj = 'cj1';

    this.dpdn_juzgados_destino_disabled = true;

    // cargo juzgados destino
    this.juzgadoService.getJuzgados().subscribe(data=> {
      if (data['data'] != undefined) {

        this.dpdn_juzgados_destino_disabled = false;

        let juzgados = data['data'];
        let cj = '';

        for (let i = 0; i < juzgados.length; i++) {
          cj = juzgados[i].cod_nomenclador.substring(0, 2);
          juzgados[i]['descripcion'] = juzgados[i]['cod_nomenclador'] + ' - ' + juzgados[i]['descripcion']

          if (cj === '01') {
            this.juzgados_cj1.push(juzgados[i]);
          } else if (cj === '02') {
            this.juzgados_cj2.push(juzgados[i]);
          } else if (cj === '03') {
            this.juzgados_cj3.push(juzgados[i]);
          } else if (cj === '04') {
            this.juzgados_cj4.push(juzgados[i]);
          }
        }
        this.dpdn_juzgados_destino = this.juzgados_cj1;
      }
    },
    error => {
      console.log('---');
      console.log('ERROR');
      console.log(error);
      this.messageService.add({severity: 'error', summary: '', detail: 'Ha ocurrido un error. Por favor reintente la operación más tarde.'});
    });

    // cargo tipos de presentaciones
    this.tipoPresentacionService.getTiposPresentacion().subscribe(data=> {
      if (data['data'] != undefined) {
        this.dpdn_tipos_presentacion = [...data['data']]
      }
    },
    error => {
      console.log('---');
      console.log('ERROR');
      console.log(error);
      this.messageService.add({severity: 'error', summary: '', detail: 'Ha ocurrido un error. Por favor reintente la operación más tarde.'});
    });

    this.clearComponents();
    this.getStorageMatriculas();
  }

  confirmCaracter(box,caracter) {
    console.log(box);
    let local_property = box.name;
    if (!box.checked) return false;
        
    this.confirmationService.confirm({
      message: 'Está seguro que desea marcar la presentación con carácter '+caracter,
      header: 'Confirmar Acción',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        return true;
      },
      reject: () => {
        box.checked=false; 
        this[local_property] = false;
      } 
    });
  }

  clearComponents() {
    this.showProgressSpinner = false;
    let profile = this.kCloakService.getProfile();
    this.showSenDocument = true;
    this.dpdn_juzgado_destino_selected = undefined;
    this.dpdn_tipo_presentacion_selected = undefined;
    this.username = profile.username;
    this.numero_matricula = profile['attributes']['matricula'][0];
    this.nombre_apellido = profile.firstName + ' ' + profile.lastName;
    this.numero_cuit = profile['attributes']['cuit'][0];
    this.in_numero_expediente = '';
    this.showPanelValidated = false;
    this.showPanelNotValidated = false;
    this.showPanelMultipleFiles = false;
    this.mensaje_success = "";
    this.dpdn_tipo_presentacion_selected = undefined;
    this.maxAdjFiles = 0;
    this.file = null;
    this.filesToUpload = [];
    this.docsInfo = [];
    this.primarySelected = 0;
    this.primary = "";
    this.urgente = false;
    this.no_publicable = false;

  }

  /// APM: Chekea el tipo de presentación elegido y habilita la subida de multiples archivos segun corresponde
  checkMultipleFiles(){
    if(this.adjFile){
      console.log(this.adjFile)
      for (let file of this.adjFile["_files"]) {  
        let index = this.filesToUpload.indexOf(file)
        this.filesToUpload.splice(index,1);
      }
    }

    if(this.dpdn_tipo_presentacion_selected && this.dpdn_tipo_presentacion_selected["cant_max_adjuntos"]){
      this.maxAdjFiles = this.dpdn_tipo_presentacion_selected["cant_max_adjuntos"]
      this.showPanelMultipleFiles = true;
    }
    else{
      this.maxAdjFiles = 0
      this.showPanelMultipleFiles = false;
    }
  }


  //// APM: se cambia evt.files por evt.currentFiles
  setFile(evt,primary) {
    console.log(evt.currentFiles)
    for (let file of evt.currentFiles) {  
      let index = this.filesToUpload.indexOf(file)
      console.log(index)
      if(index == -1){
        if(primary){
          this.primary = file.name;
          this.primarySelected = 1;
        }
        this.filesToUpload.push(file);
      }
    }
  }


  removeFile(evt,primary) {
    let index = this.filesToUpload.indexOf(evt.file)
    this.filesToUpload.splice(index,1);
    if(primary){
      this.primarySelected = 0;
      this.primary = "";
    }
  }

  continuar() {
    this.showSenDocument = true;
    this.showPanelValidated = false;
    this.showPanelNotValidated = false;
    this.clearComponents();
  }

  

  cancelar() {
    this.clearComponents();
  }

  enviarDocumento() {
    // console.log(this.filesToUpload);
    // console.log(this.urgente);
    // console.log(this.no_publicable);
    // return false;
    let _error = false;
    if (this.dpdn_juzgado_destino_selected === undefined || this.dpdn_juzgado_destino_selected === null ) {
      // si el usuario no selecciono un juzgado de destino
      _error = true;
      this.messageService.add({severity: 'warn', summary: '', detail: 'Debe seleccionar el Juzgado de Destino del documento'});
    }

    if (this.dpdn_tipo_presentacion_selected === undefined || this.dpdn_tipo_presentacion_selected === null ) {
      // si el usuario no selecciono un tipo de presentacion
      _error = true;
      this.messageService.add({severity: 'warn', summary: '', detail: 'Debe seleccionar el Tipo de Presentación'});
    }

    if (!Object.keys(this.filesToUpload).length || !this.primarySelected) {
      // si el usuario no selecciono un documento
      _error = true;
      this.messageService.add({severity: 'warn', summary: '', detail: 'Debe seleccionar el documento a enviar'});
    } 

    if (this.maxAdjFiles > 0 && Object.keys(this.filesToUpload).length > (this.maxAdjFiles+1)) {
      // si el usuario no selecciono un documento
      _error = true;
      this.messageService.add({severity: 'warn', summary: '', detail: 'Ha superado el límite de archivos adjuntos a enviar.'});
    } 

    
    if (!_error) {
      // si todo esta ok, guardo
      // guardo el numero de matricula y el nombre y apellido del profesional en local storage

      this.setProfesionalToLocalStorage();

      this.showProgressSpinner = true;
      this.messageProgressSpinner = 'Validando ...'

      this.recaptchaV3Service.execute('enviarDocumento').subscribe((token) => {

        this.sendDocumentService.captcha(token).subscribe( data => {

          let response = data.data;
          console.log("CAPTCHA RESPONSE:");
          console.log(response);
          if (response.success && response.action === 'enviarDocumento' && response.score >= environment.reCaptcha.reCAPTCHA_SCORE) {

            this.showProgressSpinner = true;
            this.messageProgressSpinner = 'Enviando documento ...'
      
            const obj_documento = {};
      
            let juzgado_descripcion = this.dpdn_juzgado_destino_selected.descripcion;
            juzgado_descripcion = juzgado_descripcion.substring(juzgado_descripcion.indexOf('-') + 2);
            
            obj_documento['id_profesional'] = this.username;
            obj_documento['juzgado_destino'] = this.dpdn_juzgado_destino_selected.id;
            obj_documento['juzgado_descripcion'] = juzgado_descripcion;
            obj_documento['juzgado_cod_nomenclador'] = this.dpdn_juzgado_destino_selected.cod_nomenclador;
            obj_documento['id_tipo_presentacion'] = this.dpdn_tipo_presentacion_selected.id;
            obj_documento['numero_matricula'] = this.numero_matricula;
            obj_documento['nombre_apellido'] = this.nombre_apellido;
            obj_documento['numero_cuit'] = this.numero_cuit;
            obj_documento['numero_expediente'] = this.in_numero_expediente;
            obj_documento['primary'] = this.primary;
            obj_documento["urgente"] = this.urgente ? "True" : "False";
            obj_documento["no_publicable"] = this.no_publicable ? "True" : "False";
            // obj_documento['urgente'] = this.urgente;
            // obj_documento['no_publicable'] = this.no_publicable;
            obj_documento['file'] = this.filesToUpload;

            this.sendDocumentService.sendDocument(obj_documento).subscribe( data => {
      
              this.showProgressSpinner = false;
              
              console.log("DATA LOG:")
              console.log(data)

              if(data["data"]){
                for (let i = 0; i < data["data"].length; i++) {
                  data["data"][i]["size"] = Math.round((data["data"][i]["size"] / 1024)*100)/100;
                };
              }

              if (!data['error_code'] && !data["code"]) {
                // si todo ok
                this.uploadedFiles = data["data"];
                console.log(this.uploadedFiles)
                this.showSenDocument = false
                this.showPanelValidated = true;
                this.docsInfo = data["data"];
                // this.docsInfo[0]["urgente"] = this.urgente;
                // this.docsInfo[0]["no_publicable"] = this.no_publicable;
                this.mensaje_success = 'Todos los documentos fueron presentados correctamente.';
                
              } else if (data['error_code'] == "err-06") {
                // si el documento no cumple con las especificaciones técnicas necesarias. Debe disminuir la resolución del documento PDF
                this.showSenDocument = false
                this.showPanelNotValidated = true;
                this.docsInfo = data["data"]["checked_documents"];
                this.mensaje_error = 'Alguno de los documentos detallados superan el límite de tamaño por página permitido. Deberá comprimir el documento, o disminuír la resolución del mismo.';
          
              } 
              else if (data['error_code'] == "err-07") {
                // Error de documentos duplicados
                this.messageService.add({severity: 'error', summary: '', detail: 'Error: Presentación NO realizada. Envío de archivos duplicados. Revisar Documentación Adjunta'});
          
              } 
              else {
                // si existe un error general
                this.mensaje_error = 'Ha ocurrido un error al procesar el archivo.';
                this.messageService.add({severity: 'error', summary: '', detail: 'Ha ocurrido un error al procesar el archivo.'});
              } 

            },
            error => {
      
              this.showProgressSpinner = false;
              this.showSenDocument = true;
              console.log('---');
              console.log('ERROR');
              console.log(error);
              this.messageService.add({severity: 'error', summary: '', detail: 'Ha ocurrido un error (backend). Por favor reintente la operación más tarde.'});
            })

          } else {
            
            this.showProgressSpinner = false;
            this.messageService.add({severity: 'error', summary: '', detail: 'Ha ocurrido un error (captcha). Por favor reintente la operación más tarde.'});
          }
        },
        error => {

          this.showProgressSpinner = false;
          this.showSenDocument = true;
          console.log('---');
          console.log('ERROR');
          console.log(error);
          this.messageService.add({severity: 'error', summary: '', detail: 'Ha ocurrido un error (general). Por favor reintente la operación más tarde.'});
        })
      });
    }
  }

  onClickRadioCJ() {

    this.dpdn_juzgado_destino_selected = undefined;
    if (this.radio_cj === 'cj1') {
      this.dpdn_juzgados_destino = this.juzgados_cj1;
    } else if (this.radio_cj === 'cj2') {
      this.dpdn_juzgados_destino = this.juzgados_cj2;
    } else if (this.radio_cj === 'cj3') {
      this.dpdn_juzgados_destino = this.juzgados_cj3;
    } else if (this.radio_cj === 'cj4') {
      this.dpdn_juzgados_destino = this.juzgados_cj4;
    }
  }

  onFocusOutNumeroMatricula() {

    if (this.numero_matricula) {
      // si el usuario ingreso el numero de matricula

      this.numero_matricula = this.numero_matricula.toUpperCase();
      this.nombre_apellido = this.getNombreApellidoFromLocalStorage();
    }
  }

  onFocusOutNombreApellido() {

    if (this.nombre_apellido) {
      // si el usuario ingreso nombre y apellido

      this.nombre_apellido = this.nombre_apellido.toUpperCase();
    }
  }

  onFocusOutNumeroExpediente() {

    this.in_numero_expediente = this.in_numero_expediente.toUpperCase();
  }

  getStorageMatriculas() {

    // obtengo las matriculas guardadas en localstorage
    
    this.storageMatriculas = JSON.parse(localStorage.getItem("storageMatriculas"));

    if (!this.storageMatriculas) {
      this.storageMatriculas = [];
    }
  }

  getNombreApellidoFromLocalStorage() {

    let nombre_apellido = ''

    if (this.storageMatriculas) {
      // si hay matriculas guardadas en localstorage

      for (let i = 0; i < this.storageMatriculas.length; i ++) {
        // recorro todas las matriculas guardadas en localstorage

        if (this.storageMatriculas[i].numero_matricula === this.numero_matricula) {

          nombre_apellido = this.storageMatriculas[i].nombre_apellido;
          break;
        }
      }
    }
    return nombre_apellido;
  }

  setProfesionalToLocalStorage() {

    if (this.numero_matricula && this.nombre_apellido) {
      // si el usuario ingreso numero de matricula y nombre y apellido

      let en_localstorage = false;

      if (this.storageMatriculas) {
        // si hay matriculas guardadas en localstorage

        for (let i = 0; i < this.storageMatriculas.length; i ++) {
          // recorro todas las matriculas guardadas en localstorage
  
          if (this.storageMatriculas[i].numero_matricula === this.numero_matricula) {
            // si encuentro la matricula ingresada en el localstorage

            // actualizo el nombre del profesional
            this.storageMatriculas[i].nombre_apellido = this.nombre_apellido;
            en_localstorage = true;
            break;
          }
        }
      }

      if (!en_localstorage) {
        // si el profesional no esta en el localstorage

        // agrego el profesional en storageMatriculas
        let profesional = {
          numero_matricula: this.numero_matricula,
          nombre_apellido: this.nombre_apellido
        }
        this.storageMatriculas.push(profesional);
      }
    } else {
      // si no ha ingresado numero de matricula o no ha ingresado nombre y apellido

      if (!this.nombre_apellido) {
        // si no ingreso nombre y apellido

        if (this.storageMatriculas) {
          // si hay matriculas guardadas en localstorage
  
          for (let i = 0; i < this.storageMatriculas.length; i ++) {
            // recorro todas las matriculas guardadas en localstorage
    
            if (this.storageMatriculas[i].numero_matricula === this.numero_matricula) {
              // si encuentro la matricula ingresada en el localstorage
  
              // borro esta matricula de storageMatriculas
              this.storageMatriculas.splice(i, 1);
              break;
            }
          }
        }
      }
    }
    // actualizo el localstorage
    localStorage.setItem("storageMatriculas", JSON.stringify(this.storageMatriculas));
    this.getStorageMatriculas();
  }

  descargarConstancia(short_id){
    this.docsService.getDocBySID(short_id).subscribe(
      result => {
        let docById = result["data"]
        this.utils.generarConstanciaPDF(docById)
        
      },
      error => {
        console.log("OHHH THIS IS A F%&& ERRORR!!!!!")
        console.log(error)
      }
    )

  }


}



import { Component, OnInit } from '@angular/core';
import { KcloakService  } from '../service/kcloak.service';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  ambient: boolean = environment.production
  title: string = environment.title
  isLogued: boolean
  userProfile: any
  
  constructor(protected kcs: KcloakService) {
    this.isLogued = this.kcs.isAuthenticated()
    this.userProfile = this.kcs.getProfile()
   }
  
   
  
  ngOnInit() {
    

  }

  logout(){
    this.kcs.logout();
  }
  login(){
    this.kcs.login()
  }
  profile(){
    this.kcs.viewProfile()
  }

}





import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table'
import { PanelModule } from 'primeng/panel'
import { ButtonModule } from 'primeng/button'
import { MultiSelectModule } from 'primeng/multiselect';
import { ChartModule } from 'primeng/chart';
import { TooltipModule } from 'primeng/tooltip';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {CheckboxModule} from 'primeng/checkbox';



@NgModule({
    imports: [
        TableModule,
        PanelModule,
        ButtonModule,
        MultiSelectModule,
        ChartModule,
        TooltipModule,
        FileUploadModule,
        MessagesModule,
        MessageModule,
        InputTextModule,
        KeyFilterModule,
        DialogModule,
        DropdownModule,
        ToastModule,
        CardModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        OverlayPanelModule,
        ConfirmDialogModule,
        CheckboxModule
    ],

    exports: [
      TableModule,
      PanelModule,
      ButtonModule,
      MultiSelectModule,
      ChartModule,
      TooltipModule,
      FileUploadModule,
      MessagesModule,
      MessageModule,
      InputTextModule,
      KeyFilterModule,
      DialogModule,
      DropdownModule,
      ToastModule,
      CardModule,
      ProgressSpinnerModule,
      RadioButtonModule,
      OverlayPanelModule,
      ConfirmDialogModule,
      CheckboxModule
  ],
  providers: [
    MessageService
  ]

})
export class PrimeNGModule {}
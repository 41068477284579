import { Component, OnInit } from '@angular/core';
import { DocumentsService } from '../service/documents.service';
import { Message } from 'primeng/api';
import { environment } from '../../environments/environment';
import { formatDate } from '@angular/common';
import { iif } from 'rxjs';
import { exit } from 'process';


@Component({
  selector: 'app-document-download',
  templateUrl: './document-download.component.html',
  styleUrls: ['./document-download.component.css']
})
export class DocumentDownloadComponent implements OnInit {

  showInfo = false
  doc: any;
  short_id: string | undefined;
  // short_id: string = "YTACM1155";
  msgs: Message[] = [];
  showProgressSpinner = false;

  constructor(
    private downloadDocumentService: DocumentsService) {}

  ngOnInit() {
    this.showProgressSpinner = false;
  }

  cancelar() { 
    this.showInfo = false;
    this.doc = undefined;
    this.short_id = undefined;
  }
  buildData(data) {
    data["id"] = data["id"]
    data["fecha_presentacion"] = formatDate(data["uploadDate"], 'dd-MM-yyyy HH:mm', 'en-US', '+000')
    data["size"] = Math.round((data["size"] / 1024)*100)/100;
    data["estado"] = data["estados"][0]["descripcion"];

    if (data['id_error']){
      data["discardDisabled"] = true;
      data["estado_actual"] = "ERROR"
      data["estado_fecha"] = data["fecha_presentacion"]
      data["estado_observaciones"] = "Error en archivo. Código de error: "+data['id_error']
      data["commentDisabled"] = false;
      console.log(data['id_error'])  
    }

    let cj = data["nomenclador"].substring(0, 2);
    if (cj === '01') {
      data["mod_descripcion_juzgado"] = "1° CJ - "+ data["descripcion_juzgado"];
    } else if (cj === '02') {
      data["mod_descripcion_juzgado"] = "2° CJ - "+ data["descripcion_juzgado"];
    } else if (cj === '03') {
      data["mod_descripcion_juzgado"] = "3° CJ - "+ data["descripcion_juzgado"];
    } else if (cj === '04') {
      data["mod_descripcion_juzgado"] = "4° CJ - "+ data["descripcion_juzgado"];
    }

    return data
  }
  searchFile() {
    if (this.short_id === undefined || this.short_id === '') {

      this.msgs.push({
        severity: 'error',
        summary: '',
        detail: 'Debe ingresar el identificador del documento a descargar'
      });
    } else {

      this.short_id = this.short_id.trim();
      this.short_id = this.extraeCaracteresEspeciales(this.short_id);

      this.showProgressSpinner = true;

      this.downloadDocumentService.getDocBySID(this.short_id).subscribe((data: any) => {

         console.log(data);

         if (data && !data["error_code"]) {
          //  si obtengo el documento por SID
              this.doc = this.buildData(data["data"]["documento"])
              this.showInfo = true
              // this.short_id = undefined;
              this.showProgressSpinner = false;
            
          } else {
            console.log("error");
            this.showProgressSpinner = false;
            this.msgs.push({
              severity: 'info',
              summary: '',
              detail:
                'No existe un documento con el identificador ' + this.short_id
            });
            this.short_id = undefined;
            this.showInfo = false
          }
        });
    }

  }

  downloadFile() {
    //YTACM1155 --> demo doc
    if(this.doc.estado == "DESCARTADO"){
      console.log("ERROR: DOCUMENTO DESCARTADO...")
      this.msgs.push({
        severity: 'error',
        summary: '',
        detail: 'El documento ha sido descartado por el usuario.'
      });
      return 0;
    }

    this.msgs = [];

    if (this.short_id === undefined || this.short_id === '') {

      this.msgs.push({
        severity: 'error',
        summary: '',
        detail: 'Debe ingresar el identificador del documento a descargar'
      });
    } else {

        this.showProgressSpinner = true;
        console.log("descargando...")
        let url = environment.backend.default + '/pdf/?short_id=' + this.short_id;
        window.location.href=url
        this.showProgressSpinner = false;
    }
  }

  extraeCaracteresEspeciales(cadena) {
    const specialChars = '!@#$^&%*()+=-[]/{}|:<>?,.';
    for (let i = 0; i < specialChars.length; i++) {
      cadena = cadena.replace(new RegExp('\\' + specialChars[i], 'gi'), '');
    }
    return cadena;
  }
}

import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';


@Injectable({
  providedIn: 'root'
})

export class KcloakService {

  constructor(protected kcs: KeycloakService) { 
  }

  viewProfile() {
    let r = this.kcs.getKeycloakInstance().accountManagement()
    return r
  }

  getProfile() {
    let r = this.kcs.getKeycloakInstance().profile
    return r
  }

  isAuthenticated() {
    let r = this.kcs.getKeycloakInstance().authenticated
    return r
  }
  login() {
    let r = this.kcs.login()
    return r
  }
  logout() {
    let redirectUri:string = document.location.protocol+"//"+document.location.host
    console.log(redirectUri)
    let r = this.kcs.logout(redirectUri)
    return r
  }
}

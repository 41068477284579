import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// environment

import { environment } from './../../environments/environment';

// models

import { TipoPresentacion } from '../models/tipo-presentacion'

@Injectable({
  providedIn: 'root'
})
export class TipoPresentacionService {

  constructor(
    private http: HttpClient) { }

  getTiposPresentacion(): Observable<TipoPresentacion[]> {

    return this.http.get<TipoPresentacion[]>(environment.backend.default + '/tipo-presentacion/');
  }
}

export const environment = {
  production: false,
  title: "Portal de Servicios Judiciales",
  
  KeycloakConfig: { url: 'https://dev-auth.pjm.gob.ar/auth', realm: 'profesionales', clientId: 'portal' },

  backend: {
    default: "https://dev-backend.pjm.gob.ar/ged-backend-cloud-master/"
    // default: "https://dev-backend.pjm.gob.ar/ged-backend-cloud-reserved-urgent/"
  },
  reCaptcha : {
    reCAPTCHA_SITE_KEY: '6Ld_svYUAAAAACO6NKjaTrQYE1PIciZqI8sJDM3e',
    reCAPTCHA_SCRIPT: 'https://www.google.com/recaptcha/api.js?render=',
    reCAPTCHA_SCORE: 0.5
  }
};

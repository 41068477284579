import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { environment } from "../environments/environment"

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ng2-tooltip-directive';

// PRIME NG MODULES
import { PrimeNGModule } from './primeng';

// KEYCLOAK
import { DoBootstrap, ApplicationRef } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

// PROJECT COMPONENTS
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BandejaEscritosComponent } from './bandeja-escritos/bandeja-escritos.component';
import { DocumentSendComponent } from './document-send/document-send.component';
import { DocumentDownloadComponent } from './document-download/document-download.component';

// captcha
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

// routing - parche para arreglar en refresh cache (f5)
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const keycloakService = new KeycloakService();

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    BandejaEscritosComponent,
    DocumentSendComponent,
    DocumentDownloadComponent,
  ],
  imports: [
    AppRoutingModule,
    KeycloakAngularModule,
    BrowserModule,
    PrimeNGModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    TooltipModule
  ],
  providers: [
    {
      provide: KeycloakService,
      useValue: keycloakService
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptcha.reCAPTCHA_SITE_KEY }
  ],
  entryComponents: [AppComponent],
})

export class AppModule implements DoBootstrap {
  // onLoad: login-required or check-sso

  ngDoBootstrap(appRef: ApplicationRef) {
    keycloakService
      .init({
        config: environment.KeycloakConfig,
        initOptions: { onLoad: 'login-required', checkLoginIframe: false },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/clients/public','/#/document-download'],
      })
      .then(() => {
        console.log('[ngDoBootstrap] bootstrap app');
        appRef.bootstrap(AppComponent);
      })
      .catch(error => console.error('[ngDoBootstrap] init Keycloak failed', error));
  }
}

